header {
  position: fixed;
  top: 0;
  z-index: 50;
  max-width: 1920px;
  width: 100%;
  height: auto;
  background-color: #FFFFFF;
  box-shadow: 0 10px 25px rgba(40, 40, 40, 0.1);
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 0;
  @include media-breakpoint-up(lg) {
    padding: 0;
  }

  &.animated {
    transition: top 0.3s ease-in-out;
  }

  &.nav-up {
    top: -$header-height-desktop;

    &.open {
      top: 0;
    }
  }

  .logo-wrapper {
    height: 100%;
    position: relative;

    &__logo {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      max-height: 70%;
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  &.top-navigation {
    @include font-xs();
    background: $color-bright;
    color: $color-white;

    &.nav-up {
      top: -$extended-header-height-desktop;

      &.open {
        top: 0;
      }
    }

    .main-navigation {
      height: ($extended-header-height-desktop - $top-nav-height);
      line-height: ($extended-header-height-desktop - $top-nav-height);
    }
  }

  .top-navigation-wrapper {
    @include gradient-x($color-bright, $color-primary, $start-percent: 30%);
    height: $top-nav-height;
    line-height: $top-nav-height;
    text-align: right;

    .search-bar {
      display: inline-block;
      margin-right: 10px;
    }

    .top-navigation {
      display: inline-block;
      position: relative;
      margin: 0;

      &__list {
        font-size: 0.8rem;
        margin-right: 1rem !important;
        margin-bottom: 0;
      }

      &__item {
        margin-left: 40px;
        font-weight: bold;

        a {
          color: $color-white;
        }

        @include media-breakpoint-down(lg) {
          margin-left: 20px;
        }
      }
    }

    .language-switch {
      display: inline-block;
      position: relative;
    }
  }

  .main-navigation {
    @include font-s();
    text-align: right;
    height: ($header-height-desktop - ($header-space * 2));

    &__list {
      position: relative;
      top: 50%;
      margin: 0;
      transform: translate(0, -50%);
      display: inline-block;
      float: right;
    }

    &__item {
      margin-right: 0.7rem !important;
      line-height: 40px;
      padding-bottom: 5px;

      a {
        color: $color-white;
      }

      &.hover {
        text-decoration: none;
        border-bottom: 5px solid white;
        border-collapse: collapse;

        a {
          text-decoration: none;
        }
      }
    }
  }

  .header__background-color-row {
    height: $top-nav-height;
    right: 0;
    width: 30vw;
  }

  .icon-search_m {
    width: 16px;
    height: 16px;
    display: inline-block;
  }

}

.header__search {
  display: none;
  margin-left: 5px;
  margin-right: 10px;
  position: relative;
  top: 3px;
  @include transition(ease all 0.3s);

  input {
    text-transform: uppercase;
    outline: none !important;
    border: none;
    max-width: 0;
    @include transition(ease max-width 0.3s);
    position: relative;
    top: -2px;
    padding: 0;
    line-height: 2em;
    border: 0;
    border-radius: 0;
  }

  button {
    border: none;
    background: none;
    box-shadow: none;
    outline: none;
    position: relative;
    line-height: 1em;

    .icon {
      width: 20px;
      height: 20px;
    }

    svg {
      fill: $color-white;
    }
  }

  &.open {
    input {
      max-width: 110px;
      padding-left: 12px;
      padding-right: 12px;
      @include media-breakpoint-up(lg) {
        max-width: 250px;
      }
    }
  }

  &:hover, &.open {
    button {
      svg {
        fill: $color-grey-hover;
      }
    }
  }
}

.mm-listview {
  margin-top: -5px !important;

  li:first-child {
    &:before {
      content: '';
      border-top-width: 1px;
      border-top-style: solid;
      display: block;
      position: absolute;
      top: 0;
      left: 20px;
      right: 0;
      border-color: inherit;
    }
  }
}

.mm-listview > li > a, .mm-listview > li > span, .mm-navbar .mm-title {
  white-space: normal;
}

.mobile-menu__button {
  line-height: 15px;

  .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
    background-color: $color-white;
  }
}

#hamburger {
  padding: 17px 0;

  &:focus {
    outline: 0;
  }
}

#mobile-mmenu {
  background-color: $color-primary-sub-menu;

  a {
    color: $color-white;
  }

  .mm-searchfield {
    input {
      color: $color-white;
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: rgba($color-white, 0.5);
    }

    ::-moz-placeholder { /* Firefox 19+ */
      color: rgba($color-white, 0.5);
    }

    :-ms-input-placeholder { /* IE 10+ */
      color: rgba($color-white, 0.5);
    }

    :-moz-placeholder { /* Firefox 18- */
      color: rgba($color-white, 0.5);
    }
  }

  .mm-btn_next {
    // &:before,
    &:after {
      border-color: $color-white;
    }
  }

  .mm-btn_prev:before {
    border-color: $color-white;
  }

  /*.mm-listview li {
    &:first-child:before, &:after,
    &:first-child:before, &:after {
      border-color: $color-white;
    }
  }*/
}

.mm-searchfield__input {
  padding-top: 13px;
}

.mm-searchfield__btn {
  top: 6px;
}

.mm-menu {
  font-size: 17px;

  a {
    &:hover, .active {
      color: $color-primary;
      font-weight: $font-weight-bold;
    }

    &.footer-navigation {
      font-size: 0.9em;
      color: $color-grey-2;
      padding-top: 7px;
      padding-bottom: 7px;

      &.page-0 {
        margin-top: 30px;
      }
    }

  }
}

.editmode {
  .navigation__level-2-wrapper {
    display: none !important;
  }
}

.mm-sronly {
  display: none;
}

#mobile-mmenu {
  position: fixed;
  top: 0;
}