// media gallery

$arrow-border-distance: 35px;
$arrow-size: 75px;

.media-gallery {
  position: relative;
  border-top: 3px solid $color-primary;

  &:hover {
    .slick-arrow .icon {
      opacity: 0.8;
      transition: opacity 0.5s ;
    }
  }
  .big-slider-wrapper {
    position: relative;

    .slider-item {
      position: relative;
      picture {
        display: block;
        padding-top: 47.5%;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &__icon {
    display: none;
    position: absolute;
    fill: $color-white;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    opacity: 1;
    .icon-search_m{
      width: 50px;

    }
    .icon-plus_m {
      width: 18px;
      margin: -6px 0 0 -3px;
    }
  }

  &__image {
    &--big {
      width: 100%;
    }
    &--small {
      width: auto;
      height: 180px;
    }
  }

  .small-slider-wrapper {
    //bottom: 0;
    //left: 0;
    z-index: 4;
    width: 100%;
    position: relative;

    .slider-item {
      &:hover {
        .media-gallery__icon {
          display: block;
        }
      }
      &.slick-current {
        .media-gallery__icon {
          display: none;
        }
      }
      picture {
        display: block;
        padding-top: 100%;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .small-images-slider {
    background: $color-bright;

    .slider-item {
      margin: 4px 2px;
      position: relative;
      cursor: pointer;
    }
  }

  .image-caption {
    display: none;
  }
  .slick-arrow {
    &:before{
      content: '';
    }
    .icon {
      position: absolute;
      top: 50%;
      z-index: 4;
      padding: 10px;
      transform: translateY(-50%);
      opacity: 0;
      @include transition(opacity 0.3s);
      &:hover {
        opacity: 1;
        use {
          fill: $color-black !important;
          @include transition(all 500ms);
        }
      }
      .icon-arrow-left_m {
        margin-left: -3px;
      }
      .icon-arrow-right_m {
        margin-left: 5px;
      }
    }
    .icon.icon-arrow-left_m,
    .icon.icon-arrow-right_m {
      width: $arrow-size;
      height: $arrow-size;
      cursor: pointer;

      use {
        fill: $color-grey-1;
        @include transition(all 500ms);
      }
    }
    .icon.icon-arrow-right_m {
      right: $arrow-border-distance;
    }
    .icon.icon-arrow-left_m {
      left: $arrow-border-distance;
    }
  }
  &__image-title {
    display: none;
    position: absolute;
    top: 40px;
    color: $color-white !important;
    background: rgba($color-bright,.8);
    padding: 15px 20px;
    opacity: 1;
    left: 40px;
    text-align: left;
    text-transform: uppercase;
  }
}

@include media-breakpoint-up(md) {
  .media-gallery {
    &__image-title {
      display: block;
    }
  }
}