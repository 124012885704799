.area-contact-form {
  .form-group {
    position: relative;

    .form-check {
      padding-top: 0;
      .form-check-input {
        width: 15px;
        height: 15px;
      }
    }

    .select-wrapper {
      border: 1px solid $color-grey;
      border-radius: 0px;
      select {
        border: none;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }

    label {
      @include font-xs();
      line-height: 1.5;
      margin-bottom: 0;
    }

    input, textarea {
      border: 1px solid $color-grey;
      border-radius: 0px;
      box-shadow: none;
      &::placeholder {
        opacity: 0;
      }
      &:focus, &:not(:placeholder-shown) {
        & + label {
          top: 1px;
        }
      }
    }

  }
}

.form-check-label {
  position: relative;
  #contact_dataprivacy {
    position: absolute;
    margin: 0;
    left: -20px;
    top: 50%;
    transform: translate(0, -50%);
  }
}

.contact-person {

  &__address {
    margin-top: 30px;
  }

  &__inner {
    padding-bottom: 30px;
  }

  .bg-color-white {
    background: $color-white;
  }

  &__wrapper--without-form {
    margin: 20px 0;
    .contact-person__address {
      margin: 0;
    }
    .contact-person__name {
      margin-top: 30px;
      font-size: 18px;
      letter-spacing: 1px;
    }
    .contact-person__phone,
    .contact-person__email {
      line-height: 25px;
      .icon {
        line-height: 28px;
        margin-left: 0;
      }
    }
  }
  &__image {
    text-align: center;
    overflow: hidden;
    width: 100%;
    padding: 0;

    @include media-breakpoint-up(sm) {
      max-height: 210px;
    }
  }
  &__text {
    margin-bottom: 5px;
  }

  &__position-wrapper {
    text-align: center;

    @include media-breakpoint-up(sm) {
      margin-top: -15px;
      transform: translateX(-50%);
      left: 50%;
      position: absolute;
      z-index: 2;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
      position: relative;
    }
  }
  &--with-form {
    margin-top: -15px;
    margin-bottom: auto;
  }
  &__position {
    text-transform: uppercase;
    padding: 9px 10px;
    background: $color-yellow;
    color: $color-white;
    font-size: 14px;
    font-weight: 600;
  }
  &__name {
    text-transform: uppercase;
  }
  &__card {
    overflow: hidden;
    border: 1px solid $color-grey-2;
    background: $color-white;
  }
  &__phone, &__email {
    display: block;
    color: $color-black;
    .icon {
      width: 13px;
      height: 13px;
    }
  }
}