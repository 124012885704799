form {
  .custom-form {

    label {
      margin-bottom: 0;
    }

    .h3 {
      margin-top: $default-space;
      margin-bottom: ($default-space / 2);
    }

    input, textarea, select {
      border-radius: 0;

      &:focus {
        @include box-shadow(0 0 8px 0.1rem $color-blue);
      }
    }

    .form-control {

    }

    .form-check {
      padding-left: 1.5rem;

      .form-check-input {
        margin-left: 0;
        width: 15px;
        left: -22px;
        top: 4px;

        &:focus {
          outline: 0;
          border: none;
          box-shadow: none;
        }
      }
    }

    .form-radios {

    }

    .select-wrapper {
      .form-control {
        border: none;
      }
    }
  }

  &.loading {
    position: relative;

    .custom-form {
      opacity: 0.4;
    }

    .spinner {
      display: block;
      position: absolute;
      top: 15%;
      font-size: 2rem;
      color: $color-blue;
      left: 50%;
      @include translate-rotate(-50%, 0, 0);

      > svg {
        -webkit-animation: rotating 2s linear infinite;
        -moz-animation: rotating 2s linear infinite;
        -ms-animation: rotating 2s linear infinite;
        -o-animation: rotating 2s linear infinite;
        animation: rotating 2s linear infinite;
      }
    }
  }

  .spinner {
    display: none;
  }
}