/******************
 * BREADCRUMBS
 ******************/
.breadcrumbs-wrapper {
  background: $color-bright;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.breadcrumbs {
  color: $color-white;
  line-height: 4.5em;
  font-size: 0.5em;
  @include media-breakpoint-up(md) {
    font-size: 0.9em;
  }
  a {
    color: $color-white;
    &:hover {
      color: darken($color-grey-1,5%);
    }
  }
  .icon-arrow-right_m {
    height: 0.5em;
    width: 1.8em;
  }
  svg.icon-arrow-right_m {
    fill: white;
  }
}