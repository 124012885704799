$dimension: 300px;
$dimension-mobile: 200px;
$inner-circle-distance: 20px;
.callout {
  position: absolute;
  z-index: 5;
  right: 30px;
  top: 70px;

  &__wrapper {
    z-index: 100;
    width: 100vw;
    max-width: 1920px;
  }

  &--left {
    left: 0;
    right: auto;

    @media (max-width: 575px) {
      right: 40px;
      left: auto;
    }
  }

  .fullwidth-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 50%;
  }

  &__circle {
    top: $inner-circle-distance / 2;
    left: $inner-circle-distance / 2;
    position: absolute;
    width: $dimension-mobile - $inner-circle-distance;
    height: $dimension-mobile - $inner-circle-distance;
    border-radius: ($dimension-mobile - $inner-circle-distance ) / 2;
    border: 1px solid $color-white;
    z-index: 1;
  }

  &__container {
    width: $dimension-mobile;
    height: $dimension-mobile;
    border-radius: $dimension-mobile / 2;
    box-shadow: 0 0 30px $color-grey;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient(80deg, $color-yellow 50%, $color-yellow-1 50.1%);
  }

  &__close {
    position: absolute;
    border: 0;
    right: 0;
    top: 0;
    background: none;
    cursor: pointer;
    z-index: 1;
    width: 30px;
    height: 30px;
    .icon {
      height: 20px;
      width: 20px;
    }
    &:hover {
      use {
        fill: $color-black;
      }
    }
    use {
      fill: $color-yellow;
    }
    &--left {
      right: auto;
      left: $dimension-mobile - 20px;
    }
  }

  &__inner-wrapper {
    transform: translateY(-50%);
    margin-top: 50%;
    padding: 0 20px;

    h4 {
      @include media-breakpoint-down(lg) {
        font-size: 1rem;
      }

      @include media-breakpoint-down(md) {
        font-size: 0.8rem;
      }
    }
  }

  &__label {
    color: $color-white;
    text-transform: uppercase;
    font-weight: normal;
    white-space: pre-wrap;
    line-height: 1.2em;
    display: block;

    @include media-breakpoint-down(lg) {
      font-size: 1rem;
      margin-bottom: 10px;
    }

    @include media-breakpoint-down(md) {
      font-size: 0.8rem;
      margin-bottom: 5px;
    }
  }

  &__headline {
    width: 165px;
    color: $color-white;
    margin: 50px auto 20px auto;
    font-size: 1em;
    line-height: 1.2em;
    white-space: pre-wrap;
    text-transform: uppercase;

    @include media-breakpoint-down(lg) {
      margin-bottom: 10px;
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 5px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 16px !important;
    }
  }

  &__link {
    font-weight: normal;
    color: $color-white;
    display: inline-block;
    text-transform: uppercase;
    width: 165px;
    margin: auto;
    font-size: 0.9em;
    &:hover {
      text-decoration: none;
    }
  }

  @include media-breakpoint-up(lg) {
    right: 70px;
    top: 80px;
    &__circle {
      width: $dimension - $inner-circle-distance;
      height: $dimension - $inner-circle-distance;
      border-radius: ($dimension - $inner-circle-distance ) / 2;
    }
    &__container {
      width: $dimension;
      height: $dimension;
      border-radius: $dimension / 2;
    }
    &__headline {
      width: 208px;
      font-size: 1.6em;
      line-height: 1.2em;
      width: 230px;
    }

    &__label {
      font-weight: lighter;
      font-size: 1.2em;
    }

    &__link {
      width: 230px;
    }
    &__close {
      &--left {
        right: auto;
        left: $dimension - 20px;
      }
    }
  }
}