.area-logobar {
  //display: none;
}

.area-logo-bar {

  &__content {
    background-color: $color-white;
    border-top: 15px solid $color-primary;
    border-bottom: 15px solid $color-primary;
  }
}