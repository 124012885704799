.stage {
  &__slide {
    width: 100%;
    max-height: 649px;
    height: auto;
    overflow: hidden;
  }
  &__slide-image {
    width: 100%;
    height: auto;
    &--mobile {
      width: auto;
      height: 400px;
    }
  }
  &__headline {
    position: absolute;
    bottom: 20%;
    text-transform: uppercase;
    color: $color-white;
    font-size: #{$font-size-m}em;
    font-family: $font-family-base;
    font-weight: $font-weight-medium;
    line-height: 1.25em;
    text-shadow: 1px 1px 5px $color-black;
    transform: translateX(-50%);
    margin-left: 50%;
    &--left {
      text-align: left;
    }
    &--right {
      text-align: right;
    }
  }
  &-wrapper {
    position: relative;
    margin-bottom: 15px;
  }
}
