$iconpath: "/static/icomoon/SVG/";

/* === Bootstrap Global === */
$enable-rounded: true;
$site-wrapper-width: 1920px;

/* === Colors === */
$color-white: #FFFFFF;
$color-black: #000000;
$color-grey: #3c3c3c;
$color-grey-1: #F4F4F4;
$color-grey-2: #f5f6fb;
$color-grey-3: #FCFCFC;
$color-blue: #072D88;
$color-blue-1: #0733A3;
$color-blue-2: #0A45AF;
$color-red: #921020;
$color-yellow: #f4ab0b;
$color-yellow-1: lighten($color-yellow, 5%);

/* === Color-System === */
$colors: (
    primary: $color-blue,
    primary-bright: $color-blue-1,
    primary-sub-menu: $color-blue-2,
    background: $color-white,
    background-shade: $color-grey-1,
    danger: $color-red,
    dark-text: $color-black,
    grey-border: $color-grey-2,
    grey-hover: $color-grey-2,
    footer-list-separator: $color-white,
    mobile-navigation-shadow: $color-black,
    mobile-menu-separator: $color-grey-2,
    orange: $color-yellow
);

$color-primary: $color-blue;
$color-bright: $color-blue-1;
$color-primary-sub-menu: $color-blue-2;
$color-background: $color-white;
$color-background-shade: $color-grey-1;
$color-danger: $color-red;
$color-dark-text: $color-black;
$color-grey-border: $color-grey-2;
$color-grey-hover: $color-grey-2;
$color-footer-list-separator: $color-white;
$color-mobile-navigation-shadow: $color-black;
$color-mobile-menu-separator: $color-grey-2;
$color-orange: $color-yellow;

/* === Bricks === */
$brick-padding: 45px;
$brick-in-brick-padding: 20px;

$brick-header-bottom-space: 15px;
$brick-footer-top-space: 15px;
$brick-footer-bottom-space: 15px;

/* === Header / Navigation === */
$header-height-desktop: 40px;
$extended-header-height-desktop: 100px;
$header-space: 15px;
$top-nav-height: 40px;
$top-nav-space: 20px;


/* === Fonts === */
$font-family-base: 'Open Sans', sans-serif;

// font weight is beeing controlled via font-family
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;

// copy-s

$font-size-base: 16;
$line-height-base: 2.166666; // (24pt * 1.3333) / 16

// copy-m

$font-size-m: (30 * 1.3333) / $font-size-base;

// copy-xs

$font-size-xs: (12 * 1.3333) / $font-size-base;

$default-space: 40px; // 30px;

