.footer {
  padding: 15px 0 0;
  &__logo {
    width: 80%;
  }

  &__contact {
    white-space: pre-wrap;
    line-height: 1rem;
    font-size: 0.7rem;
    color: $color-primary;
  }
  &__contact-row {
    padding: 2rem 0;
  }
  &__contact-page{
    display: block;
    width: 100%;
    right: 0;
    background: none !important;
    color: $color-primary !important;
    svg {
      fill: $color-primary !important;
    }
    &:hover {
      background: $color-yellow !important;
      color: $color-white !important;
      svg * {
        fill: $color-white;
      }
    }
  }
  &__copyright {
    @include font-xs();
    line-height: 2.2em;
  }
  &__legal-link {
    @include font-xs();
    position: relative;
    &::after {
      content : ' ';
      border-right: 1px solid $color-footer-list-separator;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right: -7px;
      height: 40%;
    }
    &:last-child {
      &::after {
        border : none;
      }
    }
  }
  &__column-headline {
    line-height: 33px;
    font-size: 1.2em;
  }
  &__column-link {
    color: $color-black;
  }
  &__link-row-link {
    @include font-s();
  }
  &__link-row {
    .list-inline-item {
      margin-right: 0;
    }
  }

  &__bottom {
    padding: 7px 0;
  }
}

@include media-breakpoint-up(md) {
  .footer {
    &__contact-page{
      display: inline-block;
      width: auto;
      margin-right: 15px;
    }
    &__contact {
      line-height: 1.2rem;
      font-size: 1rem;
    }
  }
}

.footer__columns,.footer__link-row {
  .link-with-icon .icon {
    margin-left: 0;
  }
}

.footer-logos {
  background-color: #fff;
  padding: 30px 0;
}