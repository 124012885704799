.area-wysiwyg {
  color: $color-black;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__content {
    h1,h2 {
      line-height: normal;
    }
    p {
      line-height: 1.8em;
      margin-bottom: 2em;
    }
    h1,h2,h3 {
      margin-bottom: 1.5rem;
    }
  }

  &__wrapper {
    &.background-white {
      .teaser-object.teaser-object--page {
        border: 2px solid $color-grey-1;
      }
    }
  }
}

.area-wysiwyg-media {
  &__content {
    h3 {
      margin-bottom: 1.5rem;
    }
    p {
      line-height: 1.8em;
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.area-wysiwyg,
.area-wysiwyg-media {

  @include content-list-style();
}